<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: "AcademicDebt",
  }

</script>
<style scoped>

</style>